import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import ServiceAreas from "../../components/serviceAreas"
import SubServicesAlt from "../../components/subServicesAlt"
import HeroSection from "../../components/heroSection"
import Education from "../../components/education"
import Testimonials from "../../components/testimonials"

function Disposals() {
  const data = useStaticQuery(graphql`
    query disposalImages {
      disposal: file(relativePath: { eq: "garbage-disposal-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Garbage Disposal Repair & Replacement"
        description={`Our master plumber can repair or replace your broken garbage disposal so your home can be up and running efficiently. Call today for a 100% free quote.`}
      />
      <HeroSection
        h1={`Garbage Disposal Services`}
        h2={`Have a Master Plumber Repair Your Garbage Disposal`}
        h3={`A functioning garbage disposal is a critical part of your kitchen. If yours is jammed, burned out, or generally not working, Klein Plumbing Services will be able to help.`}
        p1={`We've repaired and replaced countless garbage disposals over three decades of serving the North Houston area, which is why our service is fast, professional, and affordable.`}
        heroImg={data.disposal.childImageSharp.fluid}
        heroImgAltTag={`Garbage Disposal Repair and Replacement`}
      />
      <Education
        mainTitle={`Signs Your Garbage Disposal Needs Repairing`}
        titleOne={`It's Leaking`}
        descriptionOne={`Leaks around the garbage disposal may indicate an incomplete seal. This can lead to water and debris making a mess under your sink.`}
        titleTwo={`It's Less Effective`}
        descriptionTwo={`Does your disposal take longer than normal to pulverize food? If so, it could be that something is wrong internally.`}
        titleThree={`It's Not Running`}
        descriptionThree={`Are you hearing weird noises or having to turn your disposal on and off a lot? That's a strong sign that your disposal needs to be repaired.`}
      />
      <SubServicesAlt
        mainTitle={`Our Garbage Disposal Services`}
        titleOne={`Garbage Disposal Repair`}
        descriptionOne={`Is your disposal making a weird sound? Is it not even turning on? No matter the cause, our experienced team will be able to fix it.`}
        titleTwo={`Jammed Garbage Disposals`}
        descriptionTwo={`Our plumbing experts can un-jam your disposal quickly so you can get back to using your kitchen sink.`}
        titleThree={`Garbage Disposal Installation`}
        descriptionThree={`Disposals, like all appliances, eventually require replacement. If your disposal is on its last leg, we can install your new unit.`}
      />
      {/* <Testimonials /> */}
      <ServiceAreas
        description={`garbage disposals`}
        subDescription={`garbage disposal repair and replacement`}
      />
      <CTA title={`Get a Free Quote to Fix Your Disposal Now`} />
    </Layout>
  )
}

export default Disposals
